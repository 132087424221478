
import { Vue, Component, Watch } from 'vue-property-decorator'
import { SearchBar, SearchToken } from '@/components/search-bar'
import { NavigationTabs, SearchTabs } from '@/components/navigation-tabs'
import { PublicationsResults } from '@/components/search-results/publications-results'
import { SearchParams } from '@/global-types'
import { defaultSearchParams, updateRouter } from '@/components/search-results/publications-results/utils'
import { updateSearchParams } from '@/utils/utils'
import { RESET_PREV_NEXT_STATE, UPDATE_EL_SCROLL_TO } from '@/store'
import { AuthorNameSuggestions } from '@/components/author-name-suggestions'

@Component({
  components: {
    NavigationTabs,
    SearchBar,
    PublicationsResults,
    AuthorNameSuggestions,
  },
})
export default class PublicationsSearch extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  searchText = ''
  searchParams: SearchParams = defaultSearchParams

  readonly publicationsTab = SearchTabs.publications

  // Wrappers are added by default in SearchControls.getDefaultFieldWrapper() for formats author, date, instcode, msc, and year.
  // Using `wrapper: '()'` would force the wrapper to be parenthesis, even if the format has a default type.
  // Wrappers should be strings that are two characters long.
  readonly searchFields: SearchToken[] = [
    { name: 'Anywhere', value: 'any', description: 'Search anywhere (including reference list) for the entered text' },
    { name: 'Author Name', value: 'au', format: 'author' },
    { name: 'Author or Related', value: 'aur', format: 'author' },
    { name: 'Authors Count', value: 'ac', format: 'number', advanced: true, description: 'The number of authors an article has' },
    { name: 'Collection ID', value: 'cid', description: 'The MR Number for the publication which has a collection of papers in it', advanced: true },
    { name: 'Classic MR', value: 'clmr', internal: true, wrapper: '""', example: 'clmr:"95h:62182"', description: 'The old version of MR numbers. This should be in quotes' },
    { name: 'Citation Group ID', value: 'cgp', description: 'Returns papers that cited the entered Journal Group ID', advanced: true },
    { name: 'Citation MR Number', value: 'cp', description: 'Returns papers that cited the entered MR Number', advanced: true },
    { name: 'Citation Publication Year', value: 'cy', format: 'year', advanced: true, example: 'cy:[YYYY]' },
    { name: 'Citation Series ID', value: 'csp', description: 'Returns papers that cited the entered Series ID', advanced: true },
    { name: 'Citations Updated', value: 'cd', internal: true, format: 'date', example: 'cd:[YYYY-MM-DD]' },
    { name: 'Date Added', value: 'mp', format: 'date', advanced: true, description: 'Date when the initial bibliographic information was added to MathSciNet', example: 'mp:[YYYY-MM-DD]' },
    { name: 'Date Cataloged', value: 'di', format: 'date', advanced: true, description: 'Date when the completed bibliographic information was added to MathSciNet', example: 'di:[YYYY-MM-DD]' },
    { name: 'Date Reviewed', value: 'dr', format: 'date', advanced: true, description: 'Date when the review was added to MathSciNet', example: 'dr:[YYYY-MM-DD]' },
    { name: 'DOI', value: 'doi', description: 'Search for the Digital Object Identifier', advanced: true },
    { name: 'Entry Type', value: 'et', internal: true },
    { name: 'Final Page', value: 'fp', example: 'fp:54', description: 'A paper with pages 14-54 could be found by searching 54', internal: true },
    { name: 'Initial Page', value: 'ip', example: 'ip:14', description: 'A paper with pages 14-54 could be found by searching 14', internal: true },
    { name: 'Institution Address', value: 'iaddr', wrapper: '()', example: 'iaddr:(Ann Arbor, Michigan)', description: 'Address for the institution an author was at when the publication was written', advanced: true },
    { name: 'Institution Code', format: 'instcode', value: 'ic', example: 'ic:"F-PARIS11"', description: 'A way of identifying what institution an author was at when the publication was written', advanced: true },
    { name: 'Institution Name', format: 'instname', value: 'inst', wrapper: '()', example: 'inst:(University of Michigan)', description: 'Search by the institution an author was at when the publication was written' },
    { name: 'ISBN', value: 'isbn', advanced: true },
    { name: 'ISSN', value: 'issn', advanced: true },
    { name: 'Issue', value: 'iss', advanced: true },
    { name: 'Item Type', value: 'it', internal: true },
    { name: 'Journal Code', value: 'jc', internal: true, description: 'A 5-digit code for the journal', example: 'jc:DUKMJ' },
    { name: 'Journal ID', value: 'ji', description: 'A numerical ID for the journal, commonly used in URLs', advanced: true },
    { name: 'Journal Name', value: 'j', format: 'journal' },
    { name: 'Journal Group ID', value: 'gi', description: 'A numerical ID used when a journal has changed over its history', advanced: true },
    { name: 'MR Author ID', value: 'auid', description: 'An ID for a specific author, commonly used in URLs', advanced: true },
    { name: 'MR Number', value: 'mr', example: 'mr:1234567', description: 'The Mathematical Reviews number.' },
    { name: 'MSC Primary or Secondary', value: 'pcsc', description: 'A 2-, 3-, or 5-digit MSC Code, in either the primary or secondary classification', format: 'msc', example: 'pcsc:03B47' },
    { name: 'MSC Primary', value: 'pc', description: 'A 2-, 3-, or 5-digit MSC Code', format: 'msc', example: 'pc:03B47' },
    { name: 'MSC Secondary', value: 'sc', description: 'A 2-, 3-, or 5-digit MSC Code', format: 'msc', example: 'sc:03B47', advanced: true },
    { name: 'Publication Type', value: 'pt', wrapper: '""', example: 'pt:"article"', description: 'Options include: article, book, book collection, collection, collection article, journal, thesis' },
    { name: 'Publication Year', value: 'y', format: 'year', example: 'y:[YYYY]' },
    // { name: 'Publisher', value: 'p' }, // Works on Journals/Series only?
    { name: 'Reference List', value: 'rl', description: 'Search the reference list for the entered text', advanced: true },
    { name: 'Related', value: 'mau', format: 'author' },
    { name: 'Review', value: 'r', description: 'Search within review or summary text' },
    { name: 'Reviewer', value: 'rn', format: 'author', wrapper: '""', example: 'rn:"Last name, first name"', advanced: true },
    { name: 'Reviewer ID', value: 'ri', internal: true, advanced: true },
    { name: 'Review Status', value: 'rs', wrapper: '""' },
    { name: 'Series Name', value: 'se', format: 'series', advanced: true },
    { name: 'Series ID', value: 'si', description: 'A numerical ID for the series, commonly used in URLs', advanced: true },
    { name: 'Status', value: 'st', internal: true, example: 'UNE, IO' },
    { name: 'Title', value: 'ti' },
    { name: 'Translated Title', value: 'tt', advanced: true, description: 'The title of a paper translated into English' },
    { name: 'Updated', value: 'ud', internal: true, format: 'date', description: 'The date when an article was last updated in the database', example: 'ud:[YYYY-MM-DD]' },
    { name: 'Volume', value: 'v', advanced: true },
  ]

  readonly searchOperators: SearchToken[] = [
    { name: 'logical AND', value: 'AND', example: 'a AND b' },
    { name: 'logical OR', value: 'OR', example: 'a OR b' },
    { name: 'logical NOT', value: 'NOT', example: 'NOT a' },
  ]

  /* -------------------------------------------------------------------------- */
  /*                                  WATCHERS                                  */
  /* -------------------------------------------------------------------------- */
  @Watch('$route', { immediate: true })
  onRouteChanged() {
    document.title = 'Publications Search - MathSciNet'

    this.$store.commit(UPDATE_EL_SCROLL_TO, `publication-${this.$store.getters.selectedPublicationId}`)

    this.resetData()
    this.updateDataFromURLParams()
  }

  /* -------------------------------------------------------------------------- */
  /*                             COMPUTED PROPERTIES                            */
  /* -------------------------------------------------------------------------- */

  get internalAccess() {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  /* -------------------------------------------------------------------------- */
  /*                               LIFECYCLE HOOKS                              */
  /* -------------------------------------------------------------------------- */

  created() {
    const localPageSize = localStorage.getItem(this.$route.name + 'PageSize')
    if (localPageSize) {
      this.searchParams.pageSize = parseInt(localPageSize)
    }
    this.updateDataFromURLParams()
  }

  async mounted() {
    document.title = 'Publications Search - MathSciNet'
    // Set the default page size to what was last used by the user
  }

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  resetData() {
    this.searchText = ''
    this.searchParams = defaultSearchParams
    this.$store.dispatch(RESET_PREV_NEXT_STATE)
  }

  updateDataFromURLParams() {
    this.searchParams = updateSearchParams(this.$route, this.searchParams)
    if (this.searchParams.query) {
      this.searchText = this.searchParams.query
    }
  }

  search(searchText: string) {
    if (searchText.trim().length > 0) {
      searchText = this.checkSpecialCases(searchText)

      this.$nextTick(() => {
        if (this.searchParams.query.trim() === this.searchText.trim()) {
          updateRouter(this.$router, true)({ ...this.searchParams, query: '' })
        }

        updateRouter(this.$router)({
          ...this.searchParams,
          query: searchText,
          facets: '',
          pageNumber: 1,
        })
      })
    }
  }

  checkSpecialCases(searchText: string): string {
    // When mr number is copied and it includes "mr" word at the beginning eg. mr:mr40000000
    const pattern = /mr:[a-zA-Z]+([0-9]+)\s*/i
    searchText = searchText.replace(pattern, 'mr:$1 ')

    const doiPattern = /doi:\(https:\/\/doi\.org\/(.+)(?=\))\)|doi:https:\/\/doi\.org\/(.+)/gm
    searchText = searchText.replace(doiPattern, 'doi:($1$2)')

    return searchText
  }

  clear() {
    if (this.$route.query.query && this.$route.query.query.toString().trim().length > 0) {
      this.$set(this.searchParams, 'query', '')
      this.$nextTick(() => {
        updateRouter(this.$router)({ ...this.searchParams, query: '' })
      })
    } else {
      this.searchText = ''
    }
  }
}
